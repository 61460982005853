import React from 'react';
import { useDragLayer } from 'react-dnd';

import { getFilenameFromURL } from './utils';

// Change the CSS to display the preview where the mose pointer is
function getItemStyles(initialOffset, currentOffset) {
    if (!initialOffset || !currentOffset) {
        return {
            display: 'none',
        };
    }
    let { x, y } = currentOffset;

    const transform = `translate(${x}px, ${y}px)`;
    return {
        transform,
        WebkitTransform: transform,
    };
}

// Modified version of Image component from ImageList.js
const Image = ({type, content}) => {
    if (type === "image") {
        return (
            <div
                style={{
                    opacity: 1,
                    width: '100%',
                    height: '100%'
                }}
            >
                <img alt={`img - ${content}`} src={content} className="file-img" />
                {content ?
                    <figcaption>{getFilenameFromURL(content)}</figcaption>
                    :
                    <></>
                }
            </div>
        );
    } else return (
        <div
            style={{
                opacity: 1,
                width: '100%',
                height: '100%'
            }}
        >
            {content ?
                <div>
                    <p>{content}</p>
                </div>
                :
                <></>
            }
        </div>
    )
};

// Modified from tutorial template
export const CustomDragLayer = (props) => {
    /* The item variable contains a field item.id of the format
        {
            "type": "image" | "textbox",
            "content": image.url (if image) or image.content (if textbox)
        }
    */
    const { itemType, isDragging, item, initialOffset, currentOffset } = useDragLayer((monitor) => ({
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        initialOffset: monitor.getInitialSourceClientOffset(),
        currentOffset: monitor.getSourceClientOffset(),
        isDragging: monitor.isDragging(),
    }));

    if (!isDragging) {
        return null;
    }

    // Default styles
    const layerStyles = {
        position: 'fixed',
        pointerEvents: 'none',
        zIndex: 100,
        left: 0,
        top: 0,
        height: '300px',
        width: (((props.listWidth) * 0.33) - 20) + 'px'
    }

    console.log("List width in drag layer is " + props.listWidth + "px\nCalculated width: " + (((props.listWidth) * 0.33) - 20) + 'px');

    return (
        <div style={layerStyles}>
            <div style={getItemStyles(initialOffset, currentOffset)}>

            {/* takes the image data and passes it into rendering component */}
            <Image type={item.id.type} content={item.id.content} />
            </div>
        </div>
    );
};

export default CustomDragLayer;