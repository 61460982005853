// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Home_Container__3CIMa {\r\n    margin-left: 5vw;\r\n    margin-right: 5vw;\r\n    margin-bottom: 1em;\r\n}", "",{"version":3,"sources":["webpack://src/components/Home/index.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":[".Container {\r\n    margin-left: 5vw;\r\n    margin-right: 5vw;\r\n    margin-bottom: 1em;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": "Home_Container__3CIMa"
};
export default ___CSS_LOADER_EXPORT___;
