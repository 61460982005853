import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { useSelector, useDispatch } from 'react-redux';
import update from 'immutability-helper';

import ImageList from './ImageList';
import { isTouchDevice } from './utils';

import './RearrangeImages.css';

const backendForDND = isTouchDevice() ? TouchBackend : HTML5Backend;

function RearrangeImages() {
  const reduxGallery = useSelector((state) => state.editGallery.gallery); // for retrieving state
  const dispatch = useDispatch(); // for changing state

  const moveImage = (dragIndex, hoverIndex) => {
    const draggedImage = reduxGallery[dragIndex];
    let rearrangedGallery = update(reduxGallery, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, draggedImage],
      ],
    });
    dispatch({
      type: 'EDIT_GALLERY',
      payload: rearrangedGallery,
    });
  };

  return (
    <div>
      {reduxGallery && reduxGallery.length > 0 && (
        <div className="rearrange-images-container">
          <h3 className="text-center">Drag to rearrange images</h3>
          <DndProvider backend={backendForDND}>
            <ImageList images={reduxGallery} moveImage={moveImage} />
          </DndProvider>
        </div>
      )}
    </div>
  );
}

export default RearrangeImages;
