import React from "react";
import { useSelector } from "react-redux";
import { Divider } from "antd";

import { galleryOptions } from "../../constants/galleryLayouts";
import AddTextBoxButton from "./formComponents/addTextBoxButton";
import CaptionImagePair from "./formComponents/captionImagePair";
import CorrectionText from "./formComponents/correctionText";
import TextBox from "./formComponents/textBox";
import "./caption.css";

const equalityCheck = (newState, state) => {
  // Check that state and newState contain the same images
  // and textboxes in the same order
  if (state.length !== newState.length) return false;
  for (let i = 0; i < state.length; i++) {
    if (state[i].metatype !== newState[i].metatype) return false;
    if (state[i].metatype === "image") {
      if (state[i].url !== newState[i].url) return false;
    } else if (state[i].metatype === "text") {
      if (state[i].id !== newState[i].id) return false;
    }
  }
  return true;
};

function CaptionsForm() {
  // If we don't use a custom equality check
  // then too many re renders will be triggered.

  // When the CaptionImagePair changes redux state, the array
  // is replaced with a copy. By default, the equality check
  // checks references, so it will always think the array has changed.
  // In reality, as long as the list of gallery items remains the same, we don't need to
  // rerender this component even if the values for each individual item change.
  // So in order to prevent this, we use a custom equality check which actually
  // compares the img urls and text box ids in the old state and the new redux state.
  const gallery = useSelector(
    (state) => state.editGallery.gallery,
    equalityCheck
  );
  const galleryLayout = useSelector((state) => state.editGallery.layout);

  return (
    <div className="caption-container">
      <h2> captions and credits </h2>
      <CorrectionText />
      <Divider />
      <AddTextBoxButton id="first" />
      {gallery.map((item, index) => {
        if (item.metatype === "image") {
          return (
            <div key={item.url} className="item-container">
              <CaptionImagePair
                key={item.url}
                index={index}
                imageTypes={galleryOptions[galleryLayout]}
              />
            </div>
          );
        } else if (item.metatype === "text") {
          return (
            <div key={item.id} className="item-container">
              <TextBox key={item.id} id={item.id} index={index} />
            </div>
          );
        }
        return null;
      })}
    </div>
  );
}

export default CaptionsForm;
