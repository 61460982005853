export const isTouchDevice = () => {
  if ('ontouchstart' in window) {
    return true;
  }
  return false;
};

// returns the file name from the url
export function getFilenameFromURL(url) {
  if (!url)
    return "";
  else
    return url.substring(url.lastIndexOf('/') + 1);
}