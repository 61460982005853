import React from "react";
import { Button, Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { MAX_CAPTION_LEN } from "../../../constants/formInput";
import AddTextBoxButton from "./addTextBoxButton";
import TextBoxWithPreview from "../../../components/TextBoxWithPreview";

function TextBox(props) {
  // Get values from redux store
  const content = useSelector(
    (state) => state.editGallery.gallery[props.index].content
  );

  const dispatch = useDispatch();

  function updateText(value) {
    dispatch({
      type: "EDIT_TEXTBOX",
      payload: {
        id: props.id,
        newText: value,
      },
    });
  }

  function deleteTextbox() {
    dispatch({
      type: "DELETE_TEXTBOX",
      payload: {
        id: props.id,
      },
    });
  }
  return (
    <div className="textbox-container">
      <Row>
        <Col span={22}>
          <TextBoxWithPreview
            label="Textbox"
            maxLength={MAX_CAPTION_LEN}
            value={content}
            onChange={updateText}
          />
        </Col>
        <Col span={2}>
          <Button danger onClick={deleteTextbox}>
            Delete
          </Button>
        </Col>
      </Row>
      <AddTextBoxButton id={props.id} />
    </div>
  );
}

export default TextBox;
