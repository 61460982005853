import React from "react";
import { Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { MAX_CAPTION_LEN } from "../../../constants/formInput";

const { TextArea } = Input;

function CorrectionText() {
  const dispatch = useDispatch();
  const correction = useSelector((state) => state.editGallery.correction);

  return (
    <div className="textbox-container">
      <div>
        <Form.Item label="Correction">
          <TextArea
            maxLength={MAX_CAPTION_LEN}
            showCount
            value={correction}
            placeholder="Leave empty if no correction has been issued"
            onChange={(e) =>
              dispatch({
                type: "EDIT_CORRECTION",
                payload: {
                  newText: e.target.value,
                },
              })
            }
          />
        </Form.Item>
      </div>
    </div>
  );
}

export default CorrectionText;
