import React from "react";
import { Select, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { MAX_CAPTION_LEN, MAX_CREDIT_LEN } from "../../../constants/formInput";
import { readableNames } from "../../../constants/galleryLayouts";
import AddTextBoxButton from "./addTextBoxButton";

const { TextArea } = Input;

function CaptionImagePair(props) {
  // Get values from redux store
  const { caption, credits, type, url } = useSelector(
    (state) => state.editGallery.gallery[props.index]
  );

  const dispatch = useDispatch();

  function updateCaption(value) {
    dispatch({
      type: "EDIT_CAPTION",
      payload: {
        url: url,
        newCaption: value,
      },
    });
  }

  function updateCredit(value) {
    dispatch({
      type: "EDIT_CREDIT",
      payload: {
        url: url,
        newCredit: value,
      },
    });
  }

  function updateType(value) {
    dispatch({
      type: "EDIT_TYPE",
      payload: {
        url: url,
        newType: value,
      },
    });
  }

  return (
    <div className="image-caption-container">
      <div className="image-caption-row">
        <img className="caption-image" src={url} alt={url} />
        <div>
          <Form.Item label="Caption">
            <TextArea
              maxLength={MAX_CAPTION_LEN}
              showCount
              value={caption}
              onChange={(e) => updateCaption(e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Credit">
            <TextArea
              maxLength={MAX_CREDIT_LEN}
              showCount
              value={credits}
              onChange={(e) => updateCredit(e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Type">
            <Select value={type} onChange={updateType}>
              {props.imageTypes.map((type) => (
                <Select.Option value={type} key={type}>
                  {readableNames[type]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </div>
      <AddTextBoxButton id={url} />
    </div>
  );
}

export default CaptionImagePair;
