// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Header_Header__32GGd {\r\n    background-color: aliceblue;\r\n    width: 100vw;\r\n    padding: 16px;\r\n    padding-right: calc(16px + 32px);   /* automatically add 32px right margin to last child */\r\n    margin-bottom: 2em;\r\n    display: flex;\r\n}\r\n\r\n.Header_HeaderItem__4VqDs {\r\n    display: flex;\r\n    margin-left: 32px;\r\n    align-self: stretch;\r\n    align-items: center;\r\n    flex-wrap: nowrap;\r\n}\r\n\r\n.Header_PageTitle__QbGQ0 {\r\n    font-size: 36px;\r\n    vertical-align: text-bottom;\r\n}\r\n\r\n.Header_HeaderButton__3o37- {\r\n    margin-left: auto;\r\n}", "",{"version":3,"sources":["webpack://src/components/Header/index.module.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,YAAY;IACZ,aAAa;IACb,gCAAgC,IAAI,sDAAsD;IAC1F,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,iBAAiB;IACjB,mBAAmB;IACnB,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,2BAA2B;AAC/B;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".Header {\r\n    background-color: aliceblue;\r\n    width: 100vw;\r\n    padding: 16px;\r\n    padding-right: calc(16px + 32px);   /* automatically add 32px right margin to last child */\r\n    margin-bottom: 2em;\r\n    display: flex;\r\n}\r\n\r\n.HeaderItem {\r\n    display: flex;\r\n    margin-left: 32px;\r\n    align-self: stretch;\r\n    align-items: center;\r\n    flex-wrap: nowrap;\r\n}\r\n\r\n.PageTitle {\r\n    font-size: 36px;\r\n    vertical-align: text-bottom;\r\n}\r\n\r\n.HeaderButton {\r\n    margin-left: auto;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Header": "Header_Header__32GGd",
	"HeaderItem": "Header_HeaderItem__4VqDs",
	"PageTitle": "Header_PageTitle__QbGQ0",
	"HeaderButton": "Header_HeaderButton__3o37-"
};
export default ___CSS_LOADER_EXPORT___;
