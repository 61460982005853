import React, { useRef, useState, useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import CustomDragLayer from './CustomDragLayer';
import { getFilenameFromURL } from './utils';

const type = 'Image'; // Need to pass which type element can be draggable

const Image = ({ image, index, moveImage }) => {
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: type,
    hover(item) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Move the content
      moveImage(dragIndex, hoverIndex);
      // Update the index for dragged item directly to avoid flickering when half dragged
      item.index = hoverIndex;
    },
  });

  /* The item variable contains a field item.id of the format
      {
          "type": "image" | "textbox",
          "content": image.url (if image) or image.content (if textbox)
      }
  */
  const [{ isDragging }, drag, preview] = useDrag({
    item: {
      type,
      id: (image.url ?
          {"type": "image", "content": image.url}
          :
          {"type": "textbox", "content": image.content}
        ),
      index
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // this useEffect hides the default preview
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  // initialize drag and drop into the element
  drag(drop(ref));

  // know if user is hovering over current image
  const [isHovering, setIsHovering] = useState(false);
  function handleMouseOver() {
    setIsHovering(true);
  };

  function handleMouseOut() {
    setIsHovering(false);
  };

  return (
    <div
      ref={ref}
      style={{ opacity: isDragging ? 0 : 1 }}
      className="file-item"
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      draggable="false"
    >
      {image.url ? (
        <img alt={`img - ${image.url}`} src={image.url} className="file-img" />
      ) : (
        <div>
          <p>{image.content}</p>
        </div>
      )}
      {/* Don't show caption for text boxes */}
      {image.url && isHovering ?
        <figcaption>{getFilenameFromURL(image.url)}</figcaption>
        :
        <></>
      }
    </div>
  );
};

const ImageList = ({ images, moveImage }) => {
  const ref = useRef(null);
  const [listWidth, setListWidth] = useState(0);

  useEffect(() => {
    if (ref.current) {
      setListWidth(ref.current.offsetWidth);
      console.log(`List width is ${ref.current.offsetWidth}px`);
    }
  }, [ref.current]);

  const renderImage = (item, index) => {
    if (item.metatype === 'image') {
      return (
        <Image
          image={item}
          index={index}
          key={`${item.url}-image`}
          moveImage={moveImage}
        />
      );
    } else if (item.metatype === 'text') {
      return (
        <Image image={item} index={index} key={item.id} moveImage={moveImage} />
      );
    }
  };

  return (
    <section ref={ref} className="file-list">
      {images.map(renderImage)}
      <CustomDragLayer listWidth={listWidth} />
    </section>
  );
};

export default ImageList;
