import React, { ReactNode } from 'react';
import { Button, Divider } from 'antd';

import css from './index.module.css';

export interface IHeaderProps {
    pageTitle?: string,
    button?: ReactNode
}

/**
 * Page Header component.
 * 
 * Displays "Gallery Maker", then (optional) a dividing vertical bar
 * and page title. At the very right, displays (optional) button.
 * 
 * @example
 * props: IHeaderProps {
 *   pageTitle?: string,
 *   button?: ReactNode
 * }
 */
export default function Header(props: IHeaderProps): JSX.Element {
    return (
        <div className={css.Header}>
            <div className={css.HeaderItem}>
                <div style={{fontSize: "36px", fontWeight: "bold"}}>
                    Gallery Maker
                </div>
            </div>
            {
                props.pageTitle && (
                    <>
                        <div className={css.HeaderItem}>
                            <Divider type={"vertical"} style={{height: "90%"}} />
                        </div>
                        <div className={`${css.HeaderItem} ${css.PageTitle}`}>
                            {props.pageTitle}
                        </div>
                    </>
                )
            }
            {
                props.button
                    ? (
                        <div className={`${css.HeaderItem} ${css.HeaderButton}`}>
                            {props.button}
                        </div>
                    ) : null
            }
        </div>
    );
}