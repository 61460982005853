
import React from 'react';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

function AddTextBoxButton(props) {
  //props: {index: number}
  // newprops: {id: string}
  const dispatch = useDispatch();
  let newID = uuidv4();
  const insertTextBox = () => {
    dispatch({
      type: 'CREATE_TEXTBOX',
      payload: {
        // index: props.index,
        location: props.id,
        id: newID,
      },
    });
  };
  return (
    <Button className="add-text-button" onClick={insertTextBox}>
      Add Text Box
    </Button>
  );
}

export default AddTextBoxButton;
