import React from 'react';
import { Link, Redirect } from 'react-router-dom';

import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import classes from './index.module.css';
import GalleryList from './gallery-list';
import SubmitButton from '../SubmitButton';
import Header from '../Header/index.tsx';
import GalleryBasicInfo from '../GalleryBasicInfo';
import { useDispatch } from 'react-redux';

function Home(props) {
  const dispatch = useDispatch();
  const resetGallery = () => {
    dispatch({
      type: 'DELETE_GALLERY',
    });
  };
  return (
    <div>
      <Header 
        pageTitle={"Galleries"}
        button={
          <Button type="default" size="large">
              <Link to={"/create"} onClick={resetGallery}>
                  <PlusOutlined style={{marginRight: "8px"}} />
                  New Gallery
              </Link>
          </Button>
        }
      />
      <div className={classes.Container}>
        <GalleryList />
      </div>
    </div>
  );
}

export default Home;
